input[type=radio]:checked, input[type=checkbox]:checked {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.form-check-input:focus, input[type=email]:focus, input[type=password]:focus, input[type=number]:focus, .form-control input[type=text]:focus {
  border-color:var(--primary-color);
  box-shadow: var(--primary-color-shadow);
}

.form-control-file {
  background-color: var(--primary-color);
}

.input-group {
  > .input-group-value {
    border: var(--bs-border-width) solid var(--bs-border-color);
    padding: 0.375rem 0.75rem;
    border-radius: 0 var(--bs-border-radius) var(--bs-border-radius) 0;
    background-color: #fff;
  }
}
